<template>
  <v-menu :open-on-click="true" :close-on-content-click="false" left :nudge-bottom="10" :open-delay="100" offset-y>
    <template v-slot:activator="{ props }">
      <v-btn v-bind="props" color="white" class="ml-4">
        <v-icon>mdi-help-circle-outline</v-icon>
        <span class="ml-1">Help</span>
      </v-btn>
    </template>
    <v-card max-width="500">
      <v-card-title class="pb-0">{{ message[0] }}</v-card-title>
      <v-card-text>
        {{ message[1] }}
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["message"],
};
</script>
