<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Statistics</h2>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <div class=" align-center mx-5"
      style="display: grid; grid-template-columns: 30% 30% 25% 15%; gap: 12px; padding-bottom: 24px;">
      <div>
        <v-menu v-model="menustart" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ props }">
            <v-text-field v-model="displaydatestart" hide-details label="Start date" class="ml-4" readonly
              v-bind="props"></v-text-field>
          </template>
          <v-date-picker v-model="datestart" @update:model-value="selectdate">
          </v-date-picker>
        </v-menu>
      </div>
      <div>
        <v-menu v-model="menuend" :close-on-content-click="false" transition="scale-transition" offset-y
          min-width="auto">
          <template v-slot:activator="{ props }">
            <v-text-field v-model="displaydateend" hide-details label="End date" readonly v-bind="props"></v-text-field>
          </template>
          <v-date-picker v-model="dateend" @update:model-value="selectdate"></v-date-picker>
        </v-menu>
      </div>
      <div style="display: flex;">
        <v-checkbox v-model="showNotRegistered" @update:model-value="updatereport(this.workspaceid)"
          hide-details></v-checkbox>
        <p style="margin: auto 0px;">Show not registered</p>
      </div>
      <div>
        <v-btn variant="text" @click="clearfilter()" width="30px">Clear</v-btn>
      </div>
    </div>
    <v-data-table dense :headers="headers" :items="statisticsResult" :sort-by.sync="sortOptions.sortBy"
      :sort-desc.sync="sortOptions.sortDesc" :options.sync="sortHandler" :items-per-page="20"
      :footer-props="footerProps" style="white-space: pre-line;" class="elevation-1">
      <template v-slot:[`item.registeredCount`]="{ item }">
        <a style="cursor:pointer" @click="showRelatedQr(item)">{{ item.registeredCount }}</a>
      </template>

      <template v-slot:[`item.reportCount`]="{ item }">
        <a style="cursor:pointer" @click="showRelatedIssues(item)">{{ item.reportCount }}</a>
      </template>

      <template v-slot:[`item.errorPercentage`]="{ item }">
        {{ item.errorPercentage }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/HelpTooltip.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'models',
  components: { Tooltip, },
  data() {
    return {
      datestart: null,
      dateend: null,
      displaydatestart: null,
      displaydateend: null,
      menustart: false,
      menuend: false,
      showNotRegistered: false,

      headers: [
        { title: 'Description', align: 'left', key: 'description', width: '40%' },
        { title: 'Registered on\n(QR Codes)', key: 'registeredCount' },
        { title: 'Reported on\n(Issues)', key: 'reportCount' },
        { title: 'Error per registered\n(%)', key: 'errorPercentage' },
        { title: 'Waiting', key: 'countWaiting' },
        { title: 'Ongoing', key: 'countOngoing' },
        { title: 'Closed', key: 'countClosed' },
      ],
      footerProps: { 'items-per-page-options': [20, 50, 100] },
      statisticsResult: [],

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },
      tooltipMessage: [
        "Statistics",
        'This section provides insights into failing inventories. "Error per registered" shows the percentage of added inventories that have failed. Note that individual items are not separated, so it could be one inventory that frequently fails. Click on "Registered on" to see which QR codes have that particular inventory added. Click on "Reported on" to see which issues have that particular inventory added.',
      ],
    }
  },
  computed: {
    ...mapGetters([
      'sort',
      'workspaceid'
    ]),
  },
  created() {
    this.updatereport(this.workspaceid);
    this.sortOptions = this.sort.statisticSort || this.sortOptions;
  },
  methods: {
    updatereport(workspaceId) {
      HTTP.post('/statistics/get/' + this.showNotRegistered + '/' + workspaceId, { datestart: this.datestart, dateend: this.dateend }).then(response => {
        this.statisticsResult = response.data
      }).catch(() => {
        this.statisticsResult = []
      })
    },
    selectdate() {
      this.menustart = false
      this.menuend = false
      if (this.datestart !== null) {
        this.displaydatestart = this.datestart.toDateString()
      }
      if (this.dateend !== null) {
        this.displaydateend = this.dateend.toDateString()
      }
      this.updatereport(this.workspaceid)
    },
    clearfilter() {
      this.datestart = null
      this.dateend = null
      this.displaydatestart = null
      this.displaydateend = null
      this.showNotRegistered = false
      this.updatereport(this.workspaceid)
    },
    showRelatedIssues(item) {
      this.$router.push({ name: 'Issues', query: { showClosed: true, inventoryId: `InventoryId(${item.id})` } });
    },
    showRelatedQr(item) {
      this.$router.push({ name: 'qr', query: { inventoryId: `InventoryId(${item.id})` } });
    },
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc }

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort
          newObj.statisticSort = objSort
          this.$store.dispatch('updateSort', newObj)
        }
      }
    },
    workspaceid(newValue) {
      this.updatereport(newValue);
    },
  },
}
</script>
