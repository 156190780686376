<template>
  <div class="adminbackground">
    <AutoLogout></AutoLogout>
    <AppBar></AppBar>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AutoLogout from "../../components/AutoLogout.vue";
import { RouterView } from "vue-router";
import AppBar from "../../components/AppBarMain.vue";
import { HTTP } from "../../plugins/backendAPI";

export default {
  components: { AppBar, AutoLogout, RouterView },
  created() {
    this.checkCurrentLogin()
    this.checkIfOrganizationFormsDisabled()
  },
  computed: {
    ...mapGetters(["organizationid", "useForms", "bearertoken"]),
  },
  methods: {
    checkCurrentLogin() {
      this.checkAuthorization()
      HTTP.get('/account/checkauthorization')
        .catch(() => {
          this.router.push({ name: "Logout" })
        })
    },
    checkAuthorization() {
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
      }
    },
    checkIfOrganizationFormsDisabled() {
      HTTP.get(`/organization/form/c/${this.organizationid}`).then((response) => {
        this.$store.dispatch('doesOrganizationUseForms', response.data)
        if (!response.data && this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
      }).catch(() => {
        this.$store.dispatch('doesOrganizationUseForms', false)
        if (this.$route.fullPath.split("/")[1] === "form") {
          this.$store.dispatch("showError", "Your organization has disabled forms. <br><br> Redirecting to issues page...");
          this.$router.push({ name: "Issues" })
        }
      })
    }
  },
};
</script>
