<template>
    <button class="fitBounds-btn" @click="$emit('fit-image-on-map')" title="Fit bounds">
        <v-icon style="color:#1976d2">mdi-image-filter-center-focus</v-icon>
    </button>
</template>

<script>
export default {
    methods: {
    },
}
</script>

<style scoped>
.fitBounds-btn {
  height: 34px;
  width:34px;
  background-color: #fff;
  border: 2px solid #ccc;
  border-radius: 4px;
}

.fitBounds-btn:hover {
  background-color: #f4f4f4;
}
</style>