<template>
  <div>
    <AppBarMobile header="Success"></AppBarMobile>
    <div class="text-center mb-5 mt-10">QR Code was successfully {{ editType === "e" ? "edited!" : "registered!" }}
    </div>
    <v-row justify="center"><i class="buttonicon far fa-thumbs-up"></i></v-row>
  </div>
</template>

<script>
import AppBarMobile from '../components/AppBarMobile.vue';
export default {
  props: ['editType'],
  components: {
    AppBarMobile
  },
  created() {
    if (this.editType !== "r" && this.editType !== "e") {
      this.$router.push({ name: "Login" })
    }
  },
  data() {
    return {}
  },
}
</script>
