<template>
    <v-overlay v-if="warningZone" value="true">
        <v-alert dense border="left" type="warning">
            You will be logged out soon without activity
        </v-alert>
    </v-overlay>
</template>

<script>
    export default {
        name: "AutoLogout",
        
        data () {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
                warningZone: false
            }
        },

        mounted () {
            this.events.forEach( event => {
                window.addEventListener(event, this.resetTimer)
            }, this)

            this.setTimers()
        },

        methods: {
            setTimers () {
                this.warningTimer = setTimeout(this.warningMessage, 14 * 60 * 1000) // 14 * 60 * 1000 minuter
                this.logoutTimer = setTimeout(this.logoutUser, 15 * 60 * 1000) // 15 * 60 * 1000 minuter
                this.warningZone = false
            },

            warningMessage () {
                this.warningZone = true
            },

            logoutUser () {
                this.$router.push('/logout')
            },

            resetTimer () {
                clearTimeout(this.warningTimer)
                clearTimeout(this.logoutTimer)

                this.setTimers()
            }
        }

    }
</script>

<style scoped>

</style>