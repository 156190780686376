<template>
  <v-menu :nudge-width="40" offset-y>
    <template v-slot:activator="{ props }">
      <v-btn class="text-white" width="100px" :color="getStatus(issueItem.status)" rounded v-bind="props"
        @click="$emit('show-status-dialog', issueItem)">
        {{ getStatusText(issueItem.status) }}
      </v-btn>
    </template>
    <v-card>
      <v-card-subtitle class="text-center pt-1">Change status</v-card-subtitle>
      <v-card-text>
        <div class="text-center">
          <v-btn class="text-white" rounded width="100px" @click="$emit('set-status', 0, issueItem)"
            :color="getStatus(0)">Waiting</v-btn>
        </div>
        <div class="my-3 text-center">
          <v-btn class="text-white" rounded width="100px" @click="$emit('set-status', 1, issueItem)"
            :color="getStatus(1)">Ongoing</v-btn>
        </div>
        <div class="text-center">
          <v-btn class="text-white" rounded width="100px" @click="$emit('set-status', 2, issueItem)"
            :color="getStatus(2)">Closed</v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: ["issueItem"],
  emits: ["set-status", "show-status-dialog"],
  methods: {
    getStatus(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "orange";
        case 2:
          return "green";
      }
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return "Waiting";
        case 1:
          return "Ongoing";
        case 2:
          return "Closed";
      }
    },
  }
}

</script>
