<template>
    <div class="adminbackground">
        <h2 v-if="displayErrorText" style="text-align: center; margin-top: 50px;">Image no longer available...</h2>
        <div v-if="imageExist" class="map-area-mapview-100">
            <MapComponent
            :qrId="-1"
            :name="pictureName"
            :latLng="latlng"
            />
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import MapComponent from '../components/MapComponent.vue'

export default {
    name: 'MapPage',
    props: {
        imageid: {
            type: String,
            default: ''
        },
        latlng: {
            type: String,
            default: ''
        }
    },
    components: {
        MapComponent,
    },
    data () {
        return {
            imageExist: false,
            displayErrorText: false,
            pictureName: null
        }
    },
    created () {
        this.getImageName(this.imageid)
    },
    methods: {
            getImageName (imageId) {
                HTTP.get('/image/checkifimageexist/' + imageId).then(response => {
                    if (response.data) {
                        this.pictureName = response.data
                        this.imageExist = true
                    } else {
                        this.displayErrorText = true
                    }
                })
        },
    }
}
</script>