<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel"
    @keydown.enter="agree">
    <v-card>
      <v-card-title style="word-break: break-word;">{{ title }}</v-card-title>
      <v-card-text v-show="!!message">
        <p v-html="message">
        </p>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="grey" text @click.native="cancel">Cancel</v-btn>
        <v-btn :color="options.color" text @click.native="agree">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data: () => ({
    isConfirmed: false,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 290,
      zIndex: 3000
    }
  }),
  watch: {
    dialog(newValue) {
      if (!newValue && !this.isConfirmed) {
        this.resolve(false)
      }
    }
  },
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.isConfirmed = true
      this.dialog = false
      this.resolve(true)
    },
    cancel() {
      this.dialog = false
      this.resolve(false)
    }
  }
}
</script>
