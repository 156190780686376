<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Workspaces</h2>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols=8>
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col cols=3>
        <v-btn color="white" @click="newitem()"><v-icon medium class="py-2 mr-2">mdi-plus</v-icon> New</v-btn>
      </v-col>
    </v-row>
    <v-data-table :search="search" dense :headers="headers" :items="tableItems" hide-default-footer disable-pagination
      class="elevation-1">
      <template v-slot:[`item.action`]="{ item }">
        <div style="display: grid; grid-template-columns: 50% 50%">
          <v-icon @click="editItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-pencil</v-icon>
          <v-icon @click="deleteItem(item)" color="grey-darken-1" size="30px" class="pa-2">mdi-delete</v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog v-model="dialog" max-width="800px" eager>
      <v-card>
        <v-card-title>
          <span class="headline">{{ this.editedItem.id === 0 ? 'New' : 'Edit' }} - Workspace</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form autocomplete="off" v-model="isFormValid" ref="workspaceform">
              <v-row>
                <v-col cols="6">
                  <v-text-field v-model="editedItem.name" required label="Workspace Name"
                    :rules="reqRules"></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-combobox required :rules="reqRules" auto-select-first item-value="id" item-title="fullname"
                    v-model="userSelected" :items="userItems" label="Primary User" return-object></v-combobox>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" style="align-items: center;">
                  <v-btn tile outlined @click="newImageDialog = true"><v-icon medium
                      class="py-2 mr-2">mdi-upload</v-icon>Upload logo</v-btn>
                  <p v-if="this.uploadedImage != null"
                    style="margin: auto; margin-right: 6px; margin-left: 12px; font-size: medium;">
                    {{
                      this.uploadedImage.filename
                    }}</p>
                  <v-icon v-if="this.uploadedImage != null"
                    @click="clearFileInput(); editedItem.imagePath = null">mdi-close</v-icon>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="dialog = false"><v-icon small class="py-2 mr-1">mdi-close</v-icon>
            Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="save()" :disabled="!isFormValid"><v-icon small
              class="py-2 mr-1">mdi-content-save</v-icon> Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newImageDialog" max-width="600px" eager>
      <v-card>
        <v-card-title>
          Select image(s)
        </v-card-title>
        <v-container>
          <form id="image-dropbox-form" enctype="multipart/form-data" novalidate>
            <div class="dropbox">
              <input id="file-input" type="file" multiple name="photos" accept="image/*" class="input-file"
                @change="filesChange($event)">
              <p v-if="!isUploadingFiles" class="upload-text">Drag your file here</p>
              <p v-if="!isUploadingFiles" class="upload-text">or click to browse.</p>
              <p v-if="!isUploadingFiles && this.uploadedImage == null" class="upload-text file-count">
                File selected:
              </p>
              <p v-if="!isUploadingFiles && this.uploadedImage != null" class="upload-text file-count">File
                selected: {{
                  this.uploadedImage.filename
                }}</p>
              <p v-if="isUploadingFiles" class="upload-text">Uploading image...</p>
            </div>
          </form>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="newImageDialog = false; clearFileInput()"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Cancel</v-btn>
          <v-btn tile outlined class="mb-3 mr-3" @click="newImageDialog = false" :disabled="!isImageFormValid"><v-icon
              small class="py-2 mr-1">mdi-content-save</v-icon> Continue</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
// import { EventBus } from '@/store/event-bus.js'
import Tooltip from '../../components/HelpTooltip.vue'

export default {
  components: { Tooltip, },
  data() {
    return {
      dialog: false,
      newImageDialog: false,
      search: '',
      footerProps: { 'items-per-page-options': [50, 100] },
      headers: [
        { title: 'Name', key: 'name' },
        { title: 'Primary contact', key: 'primaryContactFullName' },
        { title: 'Edit', key: 'action', sortable: false, width: 130 }
      ],
      tableItems: [],
      editedItem: {},
      userSelected: {},
      userItems: [],
      newItem: false,

      isUploadingFiles: false,
      uploadedImage: null,
      logoImage: null,
      workspaceId: -1,

      reqRules: [
        v => !!v || 'Field is required',
      ],
      isFormValid: false,
      isImageFormValid: false,

      tooltipMessage: [
        "Workspaces",
        'Workspaces makes it possible for you to create completely new and separate instances of the Service-Me platform. Use this option if you have separate divisions or businesses with different support teams. '
      ],
    }
  },
  watch: {
    organizationid(newValue) {
      this.getitems(newValue);
    },
  },
  created() {
    this.getitems(this.organizationid);
  },
  computed: {
    ...mapGetters([
      'workspaceid',
      'organizationid'
    ]),
  },
  methods: {
    triggerSaveDialog() {
      this.dialog = true
      setTimeout(() => {
        if (this.$refs.workspaceform) this.$refs.workspaceform.resetValidation()
      }, 50)
    },
    filesChange(e) {
      if (!e.target.files.length) {
        this.isImageFormValid = false
        return;
      }
      this.isImageFormValid = true
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = () => {
        const logoImageToUpload = {
          filedata: reader.result.replace('data:', '').replace(/^.+,/, ''),
          filename: file.name,
          filesize: file.size,
          filetype: file.type,
        }
        this.uploadedImage = logoImageToUpload
      }
      reader.readAsDataURL(file);
    },
    async saveImage() {
      this.isUploadingFiles = true

      if (this.uploadedImage.filedata != undefined) {
        await HTTP.post(`/workspace/${this.workspaceId}/save/logo`, this.uploadedImage).then((response) => {
          this.isUploadingFiles = false
          this.editedItem.imagePath = response.data
          this.newImageDialog = false
          this.clearFileInput()
        }).catch((e) => {
          this.isUploadingFiles = false
          this.clearFileInput()
          this.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
        })
      }
    },
    clearFileInput() {
      this.uploadedImage = null;
      this.isImageFormValid = false;
    },
    getitems(organizationId) {
      HTTP.get('/workspace/get/' + organizationId).then(response => {
        this.tableItems = response.data
      }).catch(e => {
        this.$store.dispatch('showError', e.response.data)
      })
    },
    getSingleWorkspace(workspace) {
      HTTP.get(`/workspace/get/${this.organizationid}/${workspace.id}`).then(response => {
        this.editedItem = response.data
        HTTP.get('/workspace/getusers/' + this.organizationid).then(response => {
          this.userItems = response.data
          this.userSelected = this.userItems.find(i => i.id === this.editedItem.primaryContactId)
        })
      }).catch((e) => {
        if (e.response.status === 404) {
          this.$store.dispatch('showError', 'Not found')
        } else {
          this.$store.dispatch('showError', 'Oops, something went wrong')
        }
      })
    },
    save() {
      var self = this;
      self.editedItem.primaryContactId = self.userSelected.id
      self.editedItem.primaryContactFullName = `${self.userSelected.firstname} ${self.userSelected.lastname}`
      HTTP.post('/workspace/save', self.editedItem).then(async response => {
        this.workspaceId = response.data.id
        if (this.uploadedImage != null) {
          await this.saveImage()
        }
        if (self.editedItem.id > 0) {
          var editedIndex = self.tableItems.findIndex(q => q.id === response.data.id);
          self.tableItems[editedIndex].name = response.data.name;
          self.tableItems[editedIndex].primaryContactId = response.data.primaryContactId;
          self.tableItems[editedIndex].primaryContactFullName = self.editedItem.primaryContactFullName;
          self.tableItems[editedIndex].imagePath = response.data.imagePath === null ? this.editedItem.imagePath : response.data.imagePath;
        } else {
          const newWorkspace = response.data;
          newWorkspace.imagePath = this.editedItem.imagePath
          self.tableItems.push(newWorkspace)
          self.$store.dispatch('addWorkspaceitem', newWorkspace)
        }
        self.clearInputField()
        self.dialog = false
      }).catch(e => {
        self.clearInputField()
        self.$store.dispatch('showError', 'Error when saving: ' + e.response.data)
      })
    },
    newitem() {
      this.clearFileInput()
      this.newItem = true
      this.editedItem = { id: 0, name: null, primaryContactId: 0, primaryContactFullName: null, organizationId: this.organizationid }
      HTTP.get('/workspace/getusers/' + this.organizationid).then(response => {
        this.userItems = response.data
        this.userSelected = this.userItems.find(i => i.id === this.editedItem.primaryContactId)
      })
      this.triggerSaveDialog()
    },
    editItem(item) {
      this.getSingleWorkspace(item)
      this.triggerSaveDialog()
      this.clearFileInput()
      var self = this
      if (item.imagePath != null) {
        this.uploadedImage = { filename: item.imagePath }
      }
      HTTP.get('/workspace/getusers/' + self.organizationid).then(response => {
        self.userItems = response.data
        self.userSelected = self.userItems.find(i => i.id === self.editedItem.primaryContactId)
      })
      self.editedItem = Object.assign({}, item)
      self.dialog = true
      setTimeout(() => {
        if (this.$refs.workspaceform) this.$refs.workspaceform.resetValidation()
      }, 50)
    },
    deleteItem(item) {
      const self = this
      this.$root.$ConfirmDialog.open('Delete item', 'Please make sure no QR codes exists in this workspace before attempting to delete.<br/><br/>All associated Inventories, Inventory Types, QR Categories, and Images will also be deleted. <br/><br/> <strong>Are you sure?</strong>', { color: 'red' }).then((response) => {
        if (response) {
          HTTP.post('/workspace/delete', item).then(() => {
            self.tableItems.splice(self.tableItems.indexOf(item), 1);
            this.$store.dispatch('deleteWorkspaceitem', item)
            if (self.workspaceid == item.id) {
              this.$store.dispatch('saveWorkspaceId', -1);
            }
          }).catch(e => {
            self.$store.dispatch('showError', e.response.data)
          })
        }
      })
    },
    clearInputField() {
      document.getElementById('image-dropbox-form').reset();
    },
    getUserFullName(item) {
      console.log(item)
      console.log(this.userSelected)
      return `${item.firstname} ${item.lastname}`;
    },
  }
}
</script>
<style scoped>
.image-dialog {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.dropbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  place-items: center;

  background-color: #EBF5FB;
  width: 100%;
  height: 100px;
  border-radius: 15px;
  border: 2px solid lightgrey;
  position: relative;
}

.input-file {
  width: 100%;
  height: 100px;
  opacity: 0;
  position: absolute;
  cursor: pointer;
}

.upload-text {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  margin: 0;
  padding: 0;
}

.file-count {
  font-weight: bold;
  font-style: italic;
}

::file-selector-button {
  display: none;
}
</style>
