import router from "./router";
import App from "./App.vue";
import store from "./store";
import "./registerServiceWorker";
import "./assets/app.scss";
import "@mdi/font/css/materialdesignicons.css";
import { createApp } from "vue";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: "mdi", // Use Material Design Icons
  },
});

// Create Vue app instance
const app = createApp(App);

// Use plugins
app.use(vuetify);
app.use(router);
app.use(store);
// Mount the app
app.mount("#app");
