<template>
  <v-app>
    <router-view></router-view>
    <v-dialog v-model="errordialog" persistent width="500">
      <v-card class="pa-4">
        <v-card-title class="headline">Message<v-icon large class="ml-3"
            color="red">mdi-alert-circle</v-icon></v-card-title>
        <v-card-text>
          <p v-html="errormessage"></p>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn tile @click="clearerror()">Ok</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
    <ConfirmDialog ref="confirm"></ConfirmDialog>
  </v-app>
</template>

<script>

import { HTTP } from '@/plugins/backendAPI'
import { mapGetters } from 'vuex'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  name: 'App',

  components: { ConfirmDialog },
  data() {
    return {
    }
  },
  mounted() {
    this.$root.$ConfirmDialog = this.$refs.confirm
  },
  created() {
    this.checkCurrentLogin()
    this.$store.dispatch('initStorage')
  },
  computed: {
    ...mapGetters([
      'errordialog',
      'errormessage',
      'isauthorized',
      'bearertoken',
    ])
  },
  methods: {
    checkCurrentLogin() {
      this.checkAuthorization()
      HTTP.get('/account/checkauthorization')
        .catch(e => {
          this.$store.dispatch('saveAuthorization', false)
          this.error = e
        })
    },
    clearerror() {
      this.$store.dispatch('clearError')
    },
    checkAuthorization() {
      if (HTTP.defaults.headers.common['Authorization'] === undefined) {
        HTTP.defaults.headers.common['Authorization'] = 'Bearer ' + this.bearertoken
      }
    }
  }
}
</script>

<style></style>
