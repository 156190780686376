<template>
  <v-card style="width: 75vw; margin: 32px auto">
    <div class="d-flex align-start" style="padding: 24px;">
      <h2 class="mr-auto">Issues</h2>
      <ExportButton v-if="isadministrator || issuperadministrator" :table-items="tableItems" :table-type="'issues'"
        :export-message="'Export List'"></ExportButton>
      <Tooltip :message="tooltipMessage"></Tooltip>
    </div>
    <v-row class="d-flex align-center mx-5 mb-3">
      <v-col cols="8">
        <v-text-field v-model="search" label="Search" hide-details clearable></v-text-field>
      </v-col>
      <v-col>
        <v-checkbox v-model="showclosed" label="Closed Issues" @update:model-value="getitems(workspaceid)" hide-details>
        </v-checkbox>
      </v-col>
      <v-col>
        <v-checkbox label="My issues" v-model="showUserIssues" @update:model-value="changeShowUserIssues()"
          hide-details></v-checkbox>
      </v-col>
    </v-row>
    <v-data-table :search="search" :headers="headers" :items="tableItems" :custom-filter="filterIssuesByInventoryId"
      ef="issues_data_table" class="elevation-1">
      <template v-slot:item="{ item }">
        <tr v-if="item != selectedItemActive" @click="showdetailsdialog(item)" class="issue-list table-hover">
          <td>{{ item.displayType }}</td>
          <td>{{ item.header }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.created }}</td>
          <td>
            <StatusButton :issue-item="item" @set-status="setStatus" @show-status-dialog="showstatusdialog">
            </StatusButton>
          </td>
        </tr>
        <tr v-else @click="showdetailsdialog(item)" class="issue-list table-hover" style="background-color: #eeeeee">
          <td>{{ item.displayType }}</td>
          <td>{{ item.header }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.created }}</td>
          <td>
            <StatusButton :issue-item="item" @set-status="setStatus" @show-status-dialog="showstatusdialog">
            </StatusButton>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-dialog v-model="detailsdialog" max-width="75vw" min-height="75vh">
      <v-card>
        <div class="d-flex">
          <v-card-title>
            <span class="headline">{{ getReportType }} - {{ selecteditem.displayType }} -
              {{ selecteditem.name }} -
              {{ selecteditem.created }}</span>
          </v-card-title>
        </div>
        <v-tabs v-model="issueDetailsTab" background-color="transparent" color="black" left>
          <v-tab value="details">Details</v-tab>
          <v-tab v-if="!!currentItemCoords && !!currentItemImageName" @click="showMapComponent" value="map">Map</v-tab>
        </v-tabs>
        <v-card-text>
          <v-tabs-window v-model="issueDetailsTab">
            <v-tabs-window-item value="details">
              <v-row>
                <v-col class="detail-text" style="overflow-y: auto">
                  <v-sheet class="ma-4" elevation="5" rounded="lg"
                    style="display: grid; grid-template-columns: 70% 30%;">
                    <v-container>
                      <v-row v-for="(issueValue, issueKey) in selectedItemDetails" :key="issueKey" class="my-2"
                        no-gutters>
                        <div style="width: 120px">
                          <p v-if="issueKey != 'skip'" class="ma-0">
                            {{ issueKey }}:
                          </p>
                          <p v-else class="mb-6"></p>
                        </div>
                        <v-col align-self="start">
                          <p class="ma-0">{{ issueValue }}</p>
                        </v-col>
                      </v-row>
                    </v-container>
                    <div class="ml-auto mt-6 mr-4">
                      <StatusButton :issue-item="selecteditem" @set-status="setStatus"
                        @show-status-dialog="showstatusdialog">
                      </StatusButton>
                    </div>
                  </v-sheet>
                </v-col>
                <v-col>
                  <a :href="pictureurl(selecteditem.picturename)" target="_blank"><img v-if="selecteditem.picturename"
                      class="issue-image" :src="pictureurl(selecteditem.picturename)" /></a>
                </v-col>
              </v-row>
            </v-tabs-window-item>
            <v-tabs-window-item value="map">
              <div v-if="detailsdialog" class="map-area-general">
                <MapComponent v-if="detailsdialog" :qrId="-1" :name="currentItemImageName" :qrs="mapMarker" />
              </div>
            </v-tabs-window-item>
          </v-tabs-window>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn tile outlined class="mb-3" @click="detailsdialog = false"><v-icon small
              class="py-2 mr-1">mdi-close</v-icon> Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { HTTP } from "@/plugins/backendAPI";
import { mapGetters } from "vuex";
import MapComponent from "../../components/MapComponent.vue";
// import { EventBus } from "@/store/event-bus.js";
import Tooltip from "../../components/HelpTooltip.vue";
import StatusButton from "../../components/StatusButton.vue";
import ExportButton from "../../components/ExportButton.vue";

export default {
  components: {
    MapComponent,
    Tooltip,
    StatusButton,
    ExportButton,
  },
  data() {
    return {
      search: this.$route.query.inventoryId,
      enableEscClear: true,
      showclosed: this.$route.query.showClosed === "true" ? true : false,
      showUserIssues: false,
      footerProps: { "items-per-page-options": [20, 50, 100] },
      headers: [
        { title: "Type", key: "type" },
        { title: "Header", key: "header" },
        { title: "Responsible", key: "name" },
        { title: "Created", key: "created" },
        { title: "Status", key: "status" },
      ],
      issueDetailsTab: null,
      editedItem: {},
      tableItems: [],
      fullTableItems: [],
      searchedItems: [],
      inventorytypeItems: [],
      inventorytypeSelected: null,
      selecteditem: {},
      selectedItemDetails: {},
      selectedItemActive: {},
      mapMarker: null,
      tooltipMessage: [
        "Issues",
        "On this page, you can manage your issues/reports. Click on an issue to view its details. Change the status by clicking on the current status and selecting a new one. All contents of the listed issues are searchable, including the reporter's email address, comments, or device names.",
      ],
      detailsdialog: false,
      showToolTipDialog: false,

      sortHandler: {},
      sortOptions: {
        sortBy: [],
        sortDesc: [],
      },

      showMap: false,
      currentItemCoords: "",
      currentItemImageName: "",
    };
  },
  computed: {
    ...mapGetters(
      [
        "sort",
        "workspaceid",
        'workspaceitems',
        'organizationid',
        "username",
        "isadministrator",
        "issuperadministrator",
        "ismyissuestoggled"
      ]
    ),
    getReportType() {
      if (
        this.selecteditem.type === "Contact request" ||
        this.selecteditem.type === "Feedback"
      )
        return this.selecteditem.type;
      return "Fault report";
    },
  },
  created() {
    this.showUserIssues = this.ismyissuestoggled
    this.getitems(this.workspaceid);
    this.sortOptions = this.sort.issueSort || this.sortOptions;
  },
  mounted() {
    document.addEventListener("keydown", this.clearSearchEsc);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.clearSearchEsc);
  },
  watch: {
    sortHandler: {
      deep: true,
      handler(obj) {
        const objSort = { sortBy: obj.sortBy, sortDesc: obj.sortDesc };

        if (JSON.stringify(objSort) !== JSON.stringify(this.sortOptions)) {
          const newObj = this.sort;
          newObj.issueSort = objSort;
          this.$store.dispatch("updateSort", newObj);
        }
      },
    },
    workspaceid(newValue) {
      this.getitems(newValue);
    },
    showUserIssues(updatedValue) {
      this.$store.dispatch("saveMyIssuesToggle", updatedValue)
    },
    detailsdialog(val) {
      if (val) {
        this.enableEscClear = false;
        this.issueDetailsTab = null;
      }
      if (!val) {
        this.showMap = false;
        this.currentItemCoords = "";
        this.currentItemImageName = "";
        setTimeout(() => {
        }, 100);
      }
    },
  },
  methods: {
    filterIssuesByInventoryId(value, search, item) {
      return item.raw.findByInventoryId == search;
    },
    changeShowUserIssues() {
      if (!this.showUserIssues) {
        this.tableItems = this.fullTableItems;
      }
      else {
        this.getUserIssues();
      }
    },
    getUserIssues() {
      const userissues = []
      this.tableItems.forEach(row => {
        if (this.username == row.email) {
          userissues.push(row);
        }
      })
      this.fullTableItems = this.tableItems;
      this.tableItems = userissues;
    },
    getitems(workspaceid) {
      HTTP.get("/issues/get/" + this.showclosed + "/" + workspaceid).then(
        (response) => {
          this.tableItems = response.data;
          if (this.showUserIssues) {
            this.getUserIssues();
          }
        }
      ).catch(() => {
        this.tableItems = []
      });
    },
    showstatusdialog(item) {
      this.selecteditem = item;
    },
    showToolTip() {
      if (this.showToolTipDialog == true) {
        this.showToolTipDialog = false;
      } else {
        this.showToolTipDialog = true;
      }
    },
    getSelectedDetails(item) {
      this.itemDate = new Date(item.created);
      (this.differentItemDate = new Intl.DateTimeFormat("en-GB", {
        dateStyle: "short",
        timeStyle: "short",
      }).format(this.itemDate))
      if (item.type === "Contact request") {
        this.selectedItemDetails = {
          "QR Category": item.qrCategory,
          QRID: item.qrid,
          Created: this.differentItemDate,
          Description: item.description,
          Location: item.location,
          Responsable: item.name,
          skip: "",
          "Issuer e-mail": item.issuerEmail,
          Message: item.message,
          //"Created": `${this.itemDate.getFullYear()}-${this.itemDate.getMonth() + 1}-${this.itemDate.getDate()} ${this.itemDate.getHours()}:${this.itemDate.getMinutes()}`,
        }
      }
      else {
        this.selectedItemDetails = {
          "QR Category": item.qrCategory,
          "Inventory Type": item.inventoryType,
          "Custom Type Description": item.aliasTypeDescription,
          Inventory: item.inventoryDescription,
          QRID: item.qrid,
          Created: this.differentItemDate,
          Description: item.description,
          Location: item.location,
          Responsable: item.name,
          skip: "",
          "Issuer e-mail": item.issuerEmail,
          Message: item.message,
          //"Created": `${this.itemDate.getFullYear()}-${this.itemDate.getMonth() + 1}-${this.itemDate.getDate()} ${this.itemDate.getHours()}:${this.itemDate.getMinutes()}`,
        };
      }
    },
    showMapComponent(e) {
      const value = e.target.innerText;
      if (value === "MAP") {
        setTimeout(() => {
          this.showMap = true;
        }, 100);
      }
    },
    getStatus(status) {
      switch (status) {
        case 0:
          return "red";
        case 1:
          return "orange";
        case 2:
          return "green";
      }
    },
    getStatustext(status) {
      switch (status) {
        case 0:
          return "Waiting";
        case 1:
          return "Ongoing";
        case 2:
          return "Closed";
      }
    },
    setStatus(status, issueItem) {
      const previousStatus = issueItem.status;
      issueItem.status = status;

      HTTP.post("/issues/savestatus", {
        id: issueItem.id,
        type: issueItem.type,
        status: status,
      })
        .then(() => { })
        .catch((e) => {
          issueItem.status = previousStatus;
          this.$store.dispatch(
            "showError",
            "Error when setting status: " + e.response.data
          );
        });
    },
    showdetailsdialog(item) {
      this.selecteditem = item;
      this.mapMarker = [{ qrId: item.qrid, qrString: item.qrid, qrDescription: item.description, latLng: item.latLng }]
      this.getSelectedDetails(item);
      this.selectedItemActive = item;
      this.currentItemCoords = item.latLng === null ? "" : item.latLng;
      this.getImageName(item.imageId);
      this.detailsdialog = true;
    },
    getImageName(imageId) {
      if (imageId !== null) {
        HTTP.get(`/image/checkifimageexist/` + imageId).then((response) => {
          this.currentItemImageName = response.data;
        }).catch(() => {
        })
      }
    },
    pictureurl(picturename) {
      // eslint-disable-next-line
      return process.env.VUE_APP_APIURL + "/shared/bilder/" + picturename;
    },
    filter(value, search, item) {
      return Object.values(item).some((prop) => {
        return String(prop).toLowerCase().includes(search.toLowerCase());
      });
    },
    clearSearchEsc(e) {
      if (e.keyCode === 27) {
        if (this.detailsdialog) {
          this.detailsdialog = false;
          return;
        }
        if (this.enableEscClear) this.search = "";
      }
    },
    updateitems(workspaceid) {
      this.getitems(workspaceid);
    },
  },
};
</script>
