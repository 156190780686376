import { createStore } from "vuex";

const store = createStore({
  state: {
    username: localStorage.username,
    isauthorized: localStorage.isauthorized,
    ismobileauthorized: localStorage.ismobileauthorized,
    isusepincodeformobileauth: localStorage.isusepincodeformobileauth,
    isadministrator: localStorage.isadministrator,
    ismyissuestoggled: false,
    useForms: localStorage.useForms,
    issuperadministrator: localStorage.issuperadministrator,
    bearertoken: localStorage.bearertoken,
    errordialog: false,
    errormessage: null,
    workingdialog: false,
    sort: localStorage.sort,
    organizationid: localStorage.organizationid,
    workspaceid: localStorage.workspaceid,
    legacyworkspaceid: localStorage.legacyworkspaceid,
    organizationitems: [],
    workspaceitems: [],
    legacyMobileParams: localStorage.legacyMobileParams,
    mobileParams: localStorage.mobileParams,
  },
  getters: {
    username: (state) => {
      return state.username;
    },
    isauthorized: (state) => {
      return state.isauthorized;
    },
    ismobileauthorized: (state) => {
      return state.ismobileauthorized;
    },
    isusepincodeformobileauth: (state) => {
      return state.isusepincodeformobileauth;
    },
    ismyissuestoggled: (state) => {
      return state.ismyissuestoggled;
    },
    useForms: (state) => {
      return state.useForms;
    },
    isadministrator: (state) => {
      return state.isadministrator === true ? true : state.isadministrator === "true" ? true : false;
    },
    issuperadministrator: (state) => {
      return state.issuperadministrator === true ? true : state.issuperadministrator === "true" ? true : false;
    },
    bearertoken: (state) => {
      return state.bearertoken;
    },
    errordialog: (state) => {
      return state.errordialog;
    },
    errormessage: (state) => {
      return state.errormessage;
    },
    workingdialog: (state) => {
      return state.workingdialog;
    },
    sort: (state) => {
      return JSON.parse(state.sort);
    },
    organizationid: (state) => {
      if (state.organizationid == undefined) {
        localStorage.organizationid = 1;
        return localStorage.organizationid;
      }
      return state.organizationid;
    },
    workspaceid: (state) => {
      if (state.workspaceid == undefined) {
        localStorage.workspaceid = 1;
        return localStorage.workspaceid;
      }
      return state.workspaceid;
    },
    legacyMobileParams: (state) => {
      return state.legacyMobileParams;
    },
    mobileParams: (state) => {
      return state.mobileParams;
    },
    legacyworkspaceid: (state) => {
      return state.legacyworkspaceid;
    },
    islegacy: (state) => {
      return parseInt(state.legacyworkspaceid) === parseInt(state.workspaceid);
    },
    organizationitems: (state) => {
      return state.organizationitems;
    },
    workspaceitems: (state) => {
      return state.workspaceitems;
    },
  },
  mutations: {
    initStorage(state) {
      if (localStorage.username === "undefined") {
        localStorage.username = state.username === undefined ? "" : state.username;
      }
      if (localStorage.ismyissuestoggled === "undefined") {
        localStorage.ismyissuestoggled = state.ismyissuestoggled === undefined ? "" : state.ismyissuestoggled;
      }
      if (localStorage.isauthorized === "undefined") {
        localStorage.isauthorized = state.isauthorized === undefined ? "" : state.isauthorized;
      }
      if (localStorage.ismobileauthorized === "undefined") {
        localStorage.ismobileauthorized = state.ismobileauthorized === undefined ? "" : state.ismobileauthorized;
      }
      if (localStorage.useForms === "undefined") {
        localStorage.useForms = state.useForms === undefined ? false : state.ismobileauthorized;
      }
      if (localStorage.organizationid === "undefined") {
        localStorage.organizationid = state.organizationid === undefined ? "" : state.organizationid;
      }
      if (localStorage.workspaceid === "undefined") {
        localStorage.workspaceid = state.workspaceid === undefined ? "" : state.workspaceid;
      }
      if (localStorage.legacyworkspaceid === "undefined") {
        localStorage.legacyworkspaceid = state.legacyworkspaceid === undefined ? "" : state.legacyworkspaceid;
      }
      if (localStorage.bearertoken === "undefined") {
        localStorage.bearertoken = state.bearertoken === undefined ? "" : state.bearertoken;
      }
      if (localStorage.sort === undefined) {
        const sortObj = { sortBy: [""], sortDesc: [false] };

        state.sort = JSON.stringify({
          issueSort: { sortBy: ["created"], sortDesc: [true] },
          qrSort: sortObj,
          inventorySort: sortObj,
          statisticSort: sortObj,
          userSort: sortObj,
          qrCategorySort: sortObj,
          locationSort: sortObj,
        });

        localStorage.sort = JSON.stringify({
          issueSort: { sortBy: ["created"], sortDesc: [true] },
          qrSort: sortObj,
          inventorySort: sortObj,
          statisticSort: sortObj,
          userSort: sortObj,
          qrCategorySort: sortObj,
          locationSort: sortObj,
        });
      }
    },
    saveAuthorization(state, authinfo) {
      state.username = authinfo.username;
      state.isauthorized = authinfo.isauthorized;
      state.bearertoken = authinfo.bearertoken;
      state.isadministrator = authinfo.isadministrator;
      state.issuperadministrator = authinfo.issuperadministrator;

      localStorage.username = authinfo.username;
      localStorage.isauthorized = authinfo.isauthorized;
      localStorage.bearertoken = authinfo.bearertoken;
      localStorage.isadministrator = authinfo.isadministrator;
      localStorage.issuperadministrator = authinfo.issuperadministrator;
    },
    saveMobileAuth(state, authinfo) {
      state.ismobileauthorized = authinfo.ismobileauthorized;
      localStorage.ismobileauthorized = authinfo.ismobileauthorized;
    },
    usePinCodeForMobileAuth(state) {
      state.isusepincodeformobileauth = true;
      localStorage.isusepincodeformobileauth = true;
    },
    saveMyIssuesToggle(state, isToggled) {
      state.ismyissuestoggled = isToggled;
      localStorage.ismyissuestoggled = isToggled;
    },
    saveOrganizationId(state, organizationid) {
      state.organizationid = organizationid;
      localStorage.organizationid = organizationid;
    },
    saveWorkspaceId(state, workspaceid) {
      state.workspaceid = workspaceid;
      localStorage.workspaceid = workspaceid;
    },
    saveLegacyWorkspaceId(state, legacyworkspaceid) {
      state.legacyworkspaceid = legacyworkspaceid;
      localStorage.legacyworkspaceid = legacyworkspaceid;
    },
    saveLegacyMobileParams(state, params) {
      state.legacyMobileParams = params;
      localStorage.legacyMobileParams = params;
    },
    saveMobileParams(state, params) {
      state.mobileParams = params;
      localStorage.mobileParams = params;
    },
    doesOrganizationUseForms(state, useForms) {
      state.useForms = useForms;
      localStorage.useForms = useForms;
    },
    deleteAuthorization(state) {
      state.username = null;
      state.isauthorized = null;
      state.bearertoken = null;

      delete localStorage.username;
      delete localStorage.isauthorized;
      delete localStorage.bearertoken;
    },
    deleteMobileAuth(state) {
      state.ismobileauthorized = false;

      delete localStorage.ismobileauthorized;
    },
    dontUsePinCodeForMobileAuth(state) {
      state.isusepincodeformobileauth = false;
      delete localStorage.isusepincodeformobileauth;
    },
    showError(state, errormessage) {
      state.errormessage = errormessage;
      state.errordialog = true;
    },
    clearError(state) {
      state.errordialog = false;
      state.errormessage = null;
    },
    showWorkingStatus(state) {
      state.workingdialog = true;
    },
    clearWorkingStatus(state) {
      state.workingdialog = false;
    },
    updateSort(state, sortInfo) {
      state.sort = JSON.stringify(sortInfo);
      localStorage.sort = JSON.stringify(sortInfo);
    },
    addOrganizationitem(state, item) {
      state.organizationitems.push(item);
    },
    deleteOrganizationitem(state, item) {
      var itemindex = state.organizationitems.findIndex((oi) => oi.id === item.id);
      state.organizationitems.splice(itemindex, 1);
    },
    clearOrganizationitems(state) {
      state.organizationitems = [];
    },
    addWorkspaceitem(state, item) {
      state.workspaceitems.push(item);
    },
    deleteWorkspaceitem(state, item) {
      var itemindex = state.workspaceitems.findIndex((w) => w.id === item.id);
      state.workspaceitems.splice(itemindex, 1);
    },
    clearWorkspaceitems(state) {
      state.workspaceitems = [];
    },
  },
  actions: {
    initStorage({ commit }) {
      commit("initStorage", null);
    },
    saveAuthorization({ commit }, authinfo) {
      commit("saveAuthorization", authinfo);
    },
    saveMobileAuth({ commit }, authinfo) {
      commit("saveMobileAuth", authinfo);
    },
    usePinCodeForMobileAuth({ commit }) {
      commit("usePinCodeForMobileAuth");
    },
    saveMyIssuesToggle({ commit }, isToggled) {
      commit("saveMyIssuesToggle", isToggled);
    },
    saveOrganizationId({ commit }, organizationid) {
      commit("saveOrganizationId", organizationid);
    },
    saveWorkspaceId({ commit }, workspaceid) {
      commit("saveWorkspaceId", workspaceid);
    },
    saveLegacyWorkspaceId({ commit }, legacyworkspaceid) {
      commit("saveLegacyWorkspaceId", legacyworkspaceid);
    },
    saveLegacyMobileParams({ commit }, legacyMobileParams) {
      commit("saveLegacyMobileParams", legacyMobileParams);
    },
    saveMobileParams({ commit }, mobileParams) {
      commit("saveMobileParams", mobileParams);
    },
    doesOrganizationUseForms({ commit }, useForms) {
      commit("doesOrganizationUseForms", useForms);
    },
    addOrganizationitem({ commit }, item) {
      commit("addOrganizationitem", item);
    },
    deleteOrganizationitem({ commit }, item) {
      commit("deleteOrganizationitem", item);
    },
    dontUsePinCodeForMobileAuth({ commit }) {
      commit("dontUsePinCodeForMobileAuth");
    },
    clearOrganizationitems({ commit }) {
      commit("clearOrganizationitems", null);
    },
    addWorkspaceitem({ commit }, item) {
      commit("addWorkspaceitem", item);
    },
    deleteWorkspaceitem({ commit }, item) {
      commit("deleteWorkspaceitem", item);
    },
    clearWorkspaceitems({ commit }) {
      commit("clearWorkspaceitems", null);
    },
    deleteAuthorization({ commit }) {
      commit("deleteAuthorization", null);
    },
    deleteMobileAuth({ commit }) {
      commit("deleteMobileAuth", null);
    },
    showError({ commit }, errormessage) {
      commit("showError", errormessage);
    },
    clearError({ commit }) {
      commit("clearError", null);
    },
    showWorkingStatus({ commit }) {
      commit("showWorkingStatus", null);
    },
    clearWorkingStatus({ commit }) {
      commit("clearWorkingStatus", null);
    },
    updateSort({ commit }, sortInfo) {
      commit("updateSort", sortInfo);
    },
  },
  modules: {},
});

export default store;
