<!-- eslint-disable-next-line vue/valid-template-root -->
<template></template>

<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  //eslint-disable-next-line
  name: 'Logout',
  created() {
    HTTP.defaults.headers.common['Authorization'] = null;
    this.$store.dispatch('deleteAuthorization');
    this.$store.dispatch('saveOrganizationId', -1);
    this.$store.dispatch('saveWorkspaceId', -1);
    this.$store.dispatch('clearOrganizationitems');
    this.$store.dispatch('clearWorkspaceitems');
    this.$router.push('/login');
  }
}

</script>
