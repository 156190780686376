<template>
  <div class="d-flex justify-center login-recover-window" style="margin: 10vh auto;">
    <v-card class="elevation-12" style=" width: 100%; min-width: 20vw;">
      <v-card-text class="">
        <v-form v-model="valid" ref="form">
          <div class="py-8 text-center"><img src="./../assets/service-me-logo.png" class="biglogo" /></div>
          <v-text-field :disabled="passwordsent" required v-model="password1" label="Password"
            type="password"></v-text-field>
          <v-text-field :disabled="passwordsent" required v-model="password2" label="Enter password again"
            type="password"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="login-actions  pa-4" style="display: flex; justify-content: space-between;">
        <v-btn v-if="passwordsent" color="primary" href="/login">To login page</v-btn>
        <v-btn :disabled="passwordsent" color="primary" @click="changepassword()">Save new password</v-btn>
      </v-card-actions>
      <v-alert v-if="info" type="success">{{ info }}</v-alert>
      <v-progress-linear :active="loading" :indeterminate="loading" height="6" absolute bottom
        color="blue accent-2"></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import { HTTP } from '@/plugins/backendAPI'

export default {
  props: ['guid'],
  data() {
    return {
      valid: true,
      loading: false,
      password1: null,
      password2: null,
      info: null,
      passwordsent: false
    }
  },
  created() {
    HTTP.get('/account/validateguid/' + this.guid).catch(e => {
      this.snackbartexterror = e.response.data
      this.showsnackbarerror = true
    })
  },
  methods: {
    changepassword() {
      if (this.$refs.form.validate() && this.password1 !== null && this.password1 === this.password2) {
        this.loading = true
        HTTP.post('/account/changepassword', { password: this.password1, guid: this.guid }).then(() => {
          this.info = 'Password is changed. Login with the new password.'
          this.showsnackbar = true
          this.passwordsent = true

        }).catch(e => {
          this.$store.dispatch('showError', e.response.data)
        }).then(() => {
          this.loading = false
        })
      } else {
        this.$store.dispatch('showError', 'Enter the same password in both fields')
      }
    }
  }
}

</script>

<style lang="css" scoped></style>
